@font-face {
  font-family: 'RailwaySans';
  src: local('RailwaySans'), url(./fonts/railwaysans.woff) format('woff');
}

html, body {
  height: 100%
}

#container {
  display: flex;
  flex-direction: column;
  background-color: cadetblue;
}

button#showHideControls {
  margin-left:auto
}

.leaflet-container {
  width: 100%;
  height: 120vh;
}

.no-cursor {
  cursor: ''!important;
  pointer-events: none!important;
}

div.tfl-roundel>div {
  font-family: RailwaySans;
  text-align: center;
  font-size: small;
  font-weight: lighter;
  line-height: 100%;
  display:inline-block;
  transform: translate(-35%, 0);
  width: max-content;
  z-index: 0 !important
}

div.tfl-roundel>div {
   transform: translate(-35%, 0);
   z-index: 0 !important
}

div#name-display {
  font-family: RailwaySans;
  font-size: xx-large;
  text-align: center;
  background-color: rgb(0, 15, 159);
  color: white;
}

button#next-station-btn {
  margin-left: 25px;
  height: 75%;
  width: 90%;
}


div.header-text {
  font-size: xx-large;
  text-align: right;
}

div.game-complete {
  font-size: xx-large;
  font-family: RailwaySans;
  text-align: center;
}

div.game-complete a {
  color: rgb(0, 15, 159);
}

input#name-input {
  height: 100%;
  width: 100%;
  font-family: RailwaySans;
  font-size: xx-large;
  text-align: center;
  border-radius: 10px;
  background-color: rgb(0, 15, 159);
  color: white;
}

div#game-controls {
  padding-right: 5%;
  padding-left: 5%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

div#controls, div#helpabout {
  margin-top: 10px;
  background-color: lightgray;
  padding: 25px;
  border-radius: 10px;
  font-size: larger;
}

div.collapse-container {
  overflow: auto;
}

form {
  padding-bottom: 25px;
}

div.form-check-inline {
  margin-left: 10px;
}

.button-control svg {
  stroke-width: 10px;
  fill:red;
}

.bg-score {
  background-color: rgb(0, 15, 159)!important;
}

div#progress-bar-div {
  padding-right: 0px;
}

.progress {
  height: 3rem;
  font-size: large;
  font-weight: bold;
  font-family: RailwaySans;
  color:slategray;
  line-height: 3rem;
}

div#statistics-div {
  text-align: center;
  line-height: 3rem;
  height: 3rem;
  font-size: larger;
  padding-left: 0px;
}